import styled, { css } from "styled-components";
import { HeaderHeight, SidebarWidth, SitePaddingX, colors } from "../../styles";
import { mediaMax } from "../../common/breakpoints";

export const MenuContainer = styled.div`
  position: fixed;
  top: 0;
  height: ${HeaderHeight.L}px;
  line-height: ${HeaderHeight.L}px;
  left: 0;
  right: 0;
  background-color: white;
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.1));
  /* padding: 0 70px; */
  ${mediaMax('medium', css`
    height: ${HeaderHeight.M}px;
    line-height: ${HeaderHeight.M}px;
  `)}
  ${mediaMax('small', css`
    height: ${HeaderHeight.S}px;
    line-height: ${HeaderHeight.S}px;
    text-align: left;
  `)}
`;

export const BurgerMenu = styled.div`
  position: absolute;
  right: 20px;
  top: 32px;
  width: 25px;
  height: 20px;
  cursor: pointer;
  > div {
    width: 100%;
    margin-bottom: 5px;
    height: 3px;
    background-color: ${colors.lightGray};
    transition: all ease-out 0.35s;
  }
  ${props => props.open && `
    > div.one {
      transform: translateY(6px) rotate(45deg);
    }
    > div.two {
      // transform: rotate(-45deg);
      opacity: 0;
    }
    > div.three {
      transform: translateY(-6px) rotate(-45deg);
    }
  `}
`;

export const Table = styled.table`
  height: 100%;
  border-spacing: 0;
  ${mediaMax('small', css`
    display: none;
  `)}
`;

export const MimLogoColumn = styled.td`
  width: ${SidebarWidth.L}px;
  padding-left: ${SitePaddingX.L}px;
  ${mediaMax('medium', css`
    padding-left: ${SitePaddingX.M}px;
    width: ${SidebarWidth.M}px;
  `)}
  ${mediaMax('small', css`
    padding-left: ${SitePaddingX.S}px;
  `)}
  box-sizing: border-box;
  img {
    height: 100px;
    ${mediaMax('medium', css`
      height: 60px;
    `)}
  }
`;

export const OrgaContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-right: 10px;
  height: 100%;
  ${mediaMax('medium', css`
    margin-right: 20px;
  `)}
  a {
    height: 100%;
  }
`;

export const ColorUnderline = styled.div`
  position: absolute;
  bottom: -0;
  height: 10px;
  left: 0;
  right: 0;
  /* left: -10px;
  right: -10px; */
  background-color: #${props => props.color};
`;

export const OrganizationLogo = styled.img`
  display: inline-block;
  height: 70px;
  ${mediaMax('medium', css`
    height: 60px;
  `)}
  @media only screen and (max-width: 1000px) {
    height: 40px;
  }
  ${mediaMax('small', css`
    height: 60px;
    margin-left: 10px;
  `)}
  vertical-align: middle;
  cursor: pointer;
`;