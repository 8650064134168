import styled, { createGlobalStyle, css } from 'styled-components';
import BarlowMedium from './assets/fonts/Barlow-Medium.woff2';
import { mediaMax } from './common/breakpoints';

export const colors = {
  lightGray: '#bbb',
  darkGray: '#333',
};

export const HeaderHeight = {
  M: 100,
  L: 130,
  S: 80,
};

export const FooterHeight = {
  M: 50,
  L: 70,
  S: 110,
};

export const HeaderBottomMargin = {
  M: 40,
  L: 50,
  S: 30,
};

export const SidebarWidth = {
  M: 180,
  L: 400,
};

export const SitePaddingX = {
  S: 20,
  M: 50,
  L: 70,
};

export const absoluteFill = css`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

export const centerVertically = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Barlow';
    font-weight: normal;
    src: url('${BarlowMedium}') format('woff2');
  }
  body {
    margin: 0;
    text-align: left;
    font-size: 16px;
    line-height: 1.5em;
    overflow-x: hidden;
    font-family: 'Barlow', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    ${mediaMax('small',`
      font-size: 14px;
    `)}
  }
  h1 {
    /* margin: 0; */
    font-size: 34px;
    font-weight: 400;
    /* ${mediaMax('small',`
      font-size: 18px;
    `)} */
  }
  h2 {
    font-size: 22px;
    font-weight: 500;
    margin: 1.2em 0 0.7em;
    ${mediaMax('small',`
      font-size: 20px;
    `)}
  }
`;

export const AppContainer = styled.div`
`;
