import styled from "styled-components";
import { hintLeft, hintRight } from "../../common/animations";
import { breakPoints } from "../../common/breakpoints";

export const GalleryContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

export const MainImage = styled.div`
  padding-bottom: 75%;
  background-position: center;
  background-size: cover;
`;

export const PreviewImageContainer = styled.div`
  width: 100%;
  padding-bottom: 100%;
  cursor: pointer;
`;

export const CarouselContainer = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  @media only screen and (max-width: ${breakPoints.small.max}px) {
    padding: ${props => props.hasArrows ? '0 30px' : '0'};
  }
`;

export const PreviewImage = styled.div`
  position: absolute;
  left: 2%;
  top: 2%;
  width: 96%;
  height: 96%;
  background-position: center;
  background-size: cover;
  border: ${props => props.outline ? '1px solid #333' : 'none'};
  box-sizing: border-box;
`;

export const MainImageDescription = styled.div`
  display: inline-block;
  width: 100%;
  text-align: right;
  font-size: 12px;
`;

export const Arrow = styled.div`
  position: absolute;
  width: 30px;
  height: 35px;
  top: 50%;
  transform: translateY(-50%);
  left: ${props => props.right ? 'auto' : '-40px'};
  right: ${props => props.right ? '-40px' : 'auto'};
  cursor: pointer;
  text-align: center;
  z-index: 2;
  &:hover {
    img {
      animation: ${props => props.right ? hintRight : hintLeft} 0.4s forwards;
    }
  }
  @media only screen and (max-width: ${breakPoints.small.max}px) {
    left: ${props => props.right ? 'auto' : '-5px'};
    right: ${props => props.right ? '-5px' : 'auto'};
  }
`;

export const ArrowImg = styled.img`
  display: inline-block;
  height: 100%;
`;
