import React from 'react';
import { Date, Headline, Img, OverlayBackground, OverlayContainer, Text, Cross } from './styles';
import useNavigationStore from '../../services/NavigationService';

function Overlay({content}) {

  const close = () => {
    useNavigationStore.getState().setOverlayContent(null)
  };
  
  return (
    <OverlayBackground onClick={close}>
      <OverlayContainer onClick={e=>{e.stopPropagation()}}>
        <Date>{content.date}</Date>
        <Img src={content.img} />
        <Headline>{content.headline}</Headline>
        <Text dangerouslySetInnerHTML={{__html: content.text[0]}} />
        <Cross onClick={close}>
          <div /> <div className='two' />
        </Cross>
      </OverlayContainer>
    </OverlayBackground>
  );
}

export default Overlay;
