import create from '../utilities/zustand/create';
import axios from 'axios';

const REACT_APP_BASE_URL = 'https://backend.mimev.de'
const API_URL = REACT_APP_BASE_URL + '/api';
const xApiKey = 'qXAbI3O5QHV10qKMjdbmV';

const useDataStore = create(module, (set, get) => ({
  loaded: false,
  organizations: undefined,
  datenschutz: undefined,
  impressum: undefined,
  getAll: () => {
    axios({
      headers: {
        'x-api-key': xApiKey,
      },
      method: 'get',
      url: API_URL + '/getAll',
      }).then(res => {
        console.log('res.data', res.data);
        set({organizations: res.data.organizations, datenschutz: res.data.datenschutz, impressum: res.data.impressum});
      });
  },
}));

export default useDataStore;
