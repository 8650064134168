import styled, { css } from "styled-components";
import { FooterHeight, HeaderBottomMargin, HeaderHeight, SidebarWidth, SitePaddingX, absoluteFill } from "../../../styles";
import { mediaMax } from "../../../common/breakpoints";
import { hintLeft, hintRight } from "../../../common/animations";

export const Container = styled.div`
  ${absoluteFill}
  overflow-y: scroll;
  margin-top: ${HeaderHeight.L}px;
  margin-bottom: ${FooterHeight.L}px;
  padding: ${HeaderBottomMargin.L}px ${SitePaddingX.L}px 20px ${SidebarWidth.L}px;
  ${mediaMax('medium', css`
    margin-top: ${HeaderHeight.M}px;
    margin-bottom: ${FooterHeight.M}px;
    padding: ${HeaderBottomMargin.M}px ${SitePaddingX.M}px 20px ${SidebarWidth.M}px;
    `)};
  ${mediaMax('small', css`
    margin-top: ${HeaderHeight.S}px;
    margin-bottom: 0;
    padding: ${HeaderBottomMargin.S}px ${SitePaddingX.S}px 20px ${SitePaddingX.S}px;
  `)};
  box-sizing: border-box;
  h1 {
    line-height: 1.25em;
    color: #ccc;
    margin-top: 0;
  }
`;

export const Content = styled.div`
  float: left;
  width: 70%;
  max-width: 600px;
  figure {
    margin: 0;
    img {
      width: 100%;
    }
    figcaption {
      text-align: right;
      font-size: 12px;
    }
  }
  .alice-carousel__dots, .alice-carousel__prev-btn, .alice-carousel__next-btn {
    display: none;
  }
  blockquote {
    font-size: 18px;
    font-style: italic;
    line-height: 1.3em;
  }
  cite {
    display: block;
    padding-left: 40px;
    font-size: 12px;
    line-height: 1.4em;
    font-style: normal;
  }
  div:first-of-type {
    *:first-child {
      margin-top: 0;
    }
  }
  ${mediaMax('small', css`
    width: 100%;
  `)};
`;

export const TeamMember = styled.div`
  display: inline-block;
  width: 100%;
  div.img {
    float: left;
    width: 150px;
    height: 150px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
  }
  p {
    margin-top: 0;
  }
  ${mediaMax('small', css`
    div.img {
      width: 80px;
      height: 80px;
    }
  `)};
`;

export const MemberText = styled.div`
    float: left;
    width: ${props => props.fullWidth ? '100%' : 'calc(100% - 150px)'};
    box-sizing: border-box;
    padding-left: ${props => props.fullWidth ? 0 : 25}px;
    ${mediaMax('small', css`
      width: ${props => props.fullWidth ? '100%' : 'calc(100% - 80px)'};
  `)};
`;

export const CarouselContainer = styled.div`
  position: relative;
  width: calc(100% - 50px);
  margin: 25px 0 25px 25px;
`;

export const SingleImage = styled.img`
  width: 100%;
`;

export const Arrow = styled.div`
  position: absolute;
  width: 30px;
  height: 35px;
  top: 50%;
  transform: translateY(-50%);
  left: ${props => props.right ? 'auto' : '-30px'};
  right: ${props => props.right ? '-30px' : 'auto'};
  cursor: pointer;
  text-align: center;
  &:hover {
    img {
      animation: ${props => props.right ? hintRight : hintLeft} 0.4s forwards;
    }
  }
`;

export const ArrowImg = styled.img`
  display: inline-block;
  height: 100%;
`;

export const BlogArticle = styled.div`
  background-color: white;
  margin: 4px 8px;
  /* height: 100%; */
  /* padding: 20px; */
  div.date {
    padding: 7px 20px;
    font-size: 11px;
  }
  div.img {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
    ${mediaMax('small', css`
      height: 120px;
    `)};
  }
  div.headline {
    font-weight: bold;
    padding: 20px 20px;
    height: 20px;
    ${mediaMax('small', css`
      padding: 10px;
    `)};
    word-break: break-word;
    white-space: nowrap;    /* Verhindert Umbrüche */
    overflow: hidden;       /* Versteckt den überflüssigen Text */
    text-overflow: ellipsis;/* Fügt die drei Punkte am Ende hinzu */
  }
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
`;

export const Contact = styled.div`
  float: left;
  width: 30%;
  padding-left: 120px;
  box-sizing: border-box;
  ${mediaMax('medium', css`
    padding-left: 60px;
  `)};
  ${mediaMax('small', css`
    padding-left: 0;
    width: 100%;
    /* text-align: center; */
    margin-bottom: 15px;
  `)};
  p {
    margin-top: 0;
  }
`;
