import styled from 'styled-components';
import { absoluteFill, centerVertically, colors } from '../../styles';

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: white; //rgba(255, 255, 255, 0.8);
  a {
    display: inline-block;
    text-decoration: none;
    width: 60%;
    margin: 5px 0;
    /* &:first-child{
      margin-bottom: 30px;
    } */
  }
`;

export const BackgroundImage = styled.div`
  ${absoluteFill}
  opacity: 0.05;
  background-image: url(${props => props.img});
  background-position: right top;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const HeaderContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 110px;
  background-color: white;
  padding: 10px 30px;
  box-sizing: border-box;
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.1));
`;

export const MenuContainer = styled.div`
  ${centerVertically};
  width: 100%;
  text-align: left;
  font-size: 22px;
  margin-left: 30px;
`;

export const MenuItem = styled.div`
  text-decoration: ${props => props.underline ? "underline" : 'none'};
  padding: 12px 0;
  cursor: pointer;
`;

export const OrganizationLogo = styled.img`
  float: left;
  height: ${props => props.big ? 90 : 70}px;
`;

export const CloseButton = styled.div`
    position: absolute;
    top: 28px;
    right: 15px;
    width: 30px;
    height: 30px;
    opacity: 0.5;
    cursor: pointer;
    >div {
      position: absolute;
      width: 3px;
      height: 100%;
      background: ${colors.darkGray};
      &.one { transform: translateX(11px) rotate(-45deg); }
      &.two { transform: translateX(11px) rotate(45deg); }
    }
`;
