import React, { useMemo } from 'react';
import useDataStore from '../../services/DataService';
import { useWindowStore } from '../../services/WindowService';
import { MenuContainer, MimLogoColumn, OrgaContainer, ColorUnderline, OrganizationLogo, Table, BurgerMenu } from './styles';
import { Link, useLocation } from 'react-router-dom';
import { getPathAndId } from '../../utilities/utilities';

function Menu() {

  const organizations = useDataStore(state => state.organizations);
  const isMobile = useWindowStore(state => state.isMobile);
  const mobileMenuOpen = useWindowStore(state => state.mobileMenuOpen);

  const {pathname} = useLocation();
  const {path} = getPathAndId(pathname);

  const selectedOrganization = useMemo(() => {
    if (!organizations) return undefined;
    return organizations.find(o => (o.id === path || (o.id === 'mim' && path === '')));
  }, [organizations, path]);

  const scrollUp = () => {
    const element = document.getElementById('contentContainer');
    if (element) element.scrollTo(0, 0);
  }

  if (!organizations) return <MenuContainer />;

  return (
    <MenuContainer> 
      {isMobile && selectedOrganization && <OrganizationLogo src={selectedOrganization.logo} />}
      {isMobile && <BurgerMenu onClick={()=>{useWindowStore.getState().setMobileMenuOpen(!mobileMenuOpen)}} open={mobileMenuOpen}>
          <div className='one'/><div className='two'/><div className='three'/>
        </BurgerMenu>
      }
      {!isMobile && <Table>
          <tbody>
            <tr>
              <MimLogoColumn><Link to={'/'}><OrganizationLogo src={organizations[0].logo} /></Link></MimLogoColumn>
              <td>
                {organizations.map((o, k) => k !== 0 ? 
                  <OrgaContainer key={k}>
                    {path === o.id && <ColorUnderline color={o.color.substring(2, 9)} />}
                    <Link to={'/'+o.id} onClick={scrollUp}><OrganizationLogo src={o.logo} /></Link>
                  </OrgaContainer>
                  :
                  null
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      }
    </MenuContainer>
  );
}

export default Menu;
