import React, { useMemo, useRef, useState } from 'react';
import useDataStore from '../../../services/DataService';
import { Container, Content, Contact, TeamMember, MemberText, CarouselContainer, BlogArticle, Arrow, ArrowImg, SingleImage } from './styles';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import ArrowLeftImg from '../../../assets/images/ArrowLeft.svg';
import ArrowRightImg from '../../../assets/images/ArrowRight.svg';
import useNavigationStore from '../../../services/NavigationService';
import Gallery from '../../Gallery';
import Sidebar from '../../Sidebar';
import { useWindowStore } from '../../../services/WindowService';
import Background from '../Background';

const backendUrl = 'https://backend.mimev.de/';

function Organization({orgaID}) {

  const [lang] = useState('German');
  const langs = useMemo(()=>['German', 'English', 'Arabic', 'Persian', 'Kurdish', 'Russian', 'Ukrainian'], []); 
  const langIndex = useMemo(()=>langs.findIndex(l => l === lang), [langs, lang]);
  const carouselRef = useRef();
  const isMobile = useWindowStore(state => state.isMobile);

  const organizations = useDataStore(state => state.organizations);
  const organization = organizations && organizations.find(o => o.id === orgaID);

  const blocks = useMemo(()=>{
    if (organization) {
      const blocks = organization.blocks.map(b => {
        if (b.type === 'Text') {
          const nb = {...b};
          nb.text = b.text[langIndex].replaceAll('src="/', `src="${backendUrl}`).replaceAll('href="/', `href="${backendUrl}`); //img sources & links
          return nb;
        } else {
          return b;
        }
      });
      return blocks;
    } else {
      return [];
    }
  }, [organization, langIndex]);
  
  const renderTeamMembers = team => team.map((member, i)=>
    <TeamMember key={i}>
      {member.img && <div className='img' style={{backgroundImage: `url(${member.img})`}} />}
      <MemberText className='text' dangerouslySetInnerHTML={{__html: member.text[langIndex]}} fullWidth={!member.img} />
    </TeamMember>
  );

  const renderBlogSlides = blog => blog.map((article, i)=>
    <BlogArticle key={i} className='item' data-value={i+1} onClick={()=>{useNavigationStore.getState().setOverlayContent(article)}}>
      <div className='date'>{article.date}</div>
      <div className='img' style={{backgroundImage: `url(${article.img})`}} />
      <div className='headline' dangerouslySetInnerHTML={{__html: article.headline[langIndex]}} />
    </BlogArticle>
  );

  const renderCarousel = key => {
    return (
      <CarouselContainer key={key}>
        <AliceCarousel ref={carouselRef} items={renderBlogSlides(organization.blog)} infinite responsive={{0: {items: 2, itemsFit: 'contain'}}} />
        {organization.blog.length > 2 && (
          <>
            <Arrow onClick={()=>{carouselRef.current && carouselRef.current.slidePrev()}}>
              <ArrowImg src={ArrowLeftImg} />
            </Arrow>
            <Arrow onClick={()=>{carouselRef.current && carouselRef.current.slideNext()}} right>
              <ArrowImg src={ArrowRightImg} />
            </Arrow>
          </>
        )
        }
      </CarouselContainer>
    );
  };

  const renderBlocks = blox => blox.map((b, i) => {
    if (b.type === 'Text') {
      return <div key={i} dangerouslySetInnerHTML={{__html: b.text}} />;
    } else if (b.type === 'Team') {
      return <div key={i}>{renderTeamMembers(organization.team)}</div>
    } else if (b.type === 'Blogslider'){
      return renderCarousel(i);
    } else if (b.type === 'SingleImage'){
      return <SingleImage key={i} src={b.image} />;
    } else if (b.type === 'Gallerie'){
      return <Gallery key={i} images={organization.gallery} langIndex={langIndex}/>;
    } else {
      return null;
    }
  });

  const contact = organization && organization.contact[langIndex];

  return (
    <>
      <Background orgaID={orgaID} />
      <Container id='contentContainer'>
        {isMobile && <Contact dangerouslySetInnerHTML={{__html: contact}}/>}
        <Content id='organizationContent'>{renderBlocks(blocks)}</Content>
        {!isMobile && <Contact dangerouslySetInnerHTML={{__html: contact}}/>}
        {organization && !isMobile && <Sidebar anchors={organization.anchors} langIndex={langIndex}/>}
      </Container>
    </>
  );
}

export default Organization;
