import React from 'react';
import { Container, MenuElement } from './styles';

function Sidebar({anchors, langIndex}) {

  const scrollTo = (anchor, offset = 20) => {
    const parent = document.getElementById("contentContainer");
    const element = document.getElementById(anchor);
    if (element) {
      const elementTop = element.offsetTop - offset;
      parent.scrollTo({
        top: elementTop,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Container>
      {anchors.map((a, k) => <MenuElement key={k} onClick={()=>{scrollTo(a.anchor)}}>{a.title[langIndex]}</MenuElement>)}
    </Container>
  );
}

export default Sidebar;
