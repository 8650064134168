import styled, { css } from "styled-components";
import { HeaderHeight } from "../../../styles";
import { mediaMax } from "../../../common/breakpoints";

export const Container = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: ${HeaderHeight.L}px;
  ${mediaMax('medium', css`
    top: ${HeaderHeight.M}px;
  `)}
  ${mediaMax('small', css`
    top: ${HeaderHeight.S}px;
  `)}
`;

export const ImgLeft = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  left: 0;
  opacity: 0.1;
  background-image: url(${props => props.img});
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: contain;
  ${mediaMax('small', css`
    width: 85%;
  `)}
`;

export const ImgRight = styled(ImgLeft)`
  left: auto;
  right: 0;
  background-position: right top;
`;
