import React, { useMemo } from 'react';
import { FooterContainer, LeftContainer, RightContainer, Img, LeftAligned, FooterText, RightAligned } from './styles';
import { Link, useLocation } from 'react-router-dom';
import BerlinLogo from './BerlinLogo.png';
import MarzahnLogo from './MarzahnLogo.png';
import { getPathAndId } from '../../utilities/utilities';
import useDataStore from '../../services/DataService';

function Footer() {
  
  // const [lang] = useState('German');
  // const langs = useMemo(()=>['German', 'English', 'Arabic', 'Persian', 'Kurdish', 'Russian', 'Ukrainian'], []); 
  // const langIndex = useMemo(()=>langs.findIndex(l => l === lang), [langs, lang]);

  const organizations = useDataStore(state => state.organizations);
  const {pathname} = useLocation();
  const {path} = getPathAndId(pathname);

  const selectedOrganization = useMemo(() => {
    if (!organizations) return undefined;
    return organizations.find(o => (o.id === path || (o.id === 'mim' && path === '')));
  }, [organizations, path]);

  return (
    <FooterContainer>
      <LeftContainer>
        <LeftAligned>©2023 MIM e.V.</LeftAligned>
        <LeftAligned><Link to="/datenschutz">Datenschutz</Link></LeftAligned>
        <LeftAligned><Link to="/impressum">Impressum</Link></LeftAligned>
      </LeftContainer>
      <FooterText>
        {selectedOrganization && selectedOrganization.footertext[0]}
      </FooterText>
      <RightContainer>
        <RightAligned>
          <a href='https://www.berlin.de/' target='_blank' rel="noreferrer">
            <Img src={BerlinLogo} alt='Berlin Logo'/>
          </a>
        </RightAligned>
        <RightAligned>
          <a href='https://dein-marzahn-hellersdorf.berlin/' target='_blank' rel="noreferrer">
            <Img src={MarzahnLogo} alt='Marzahn Logo'/>
          </a>
        </RightAligned>
      </RightContainer>
    </FooterContainer>
  );
}

export default Footer;
