import styled, { css } from "styled-components";
import { mediaMax } from "../../common/breakpoints";
import { colors } from "../../styles";

export const OverlayBackground = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
`;

export const OverlayContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.25));
  width: 800px;
  height: auto;
  max-width: calc(100% - 60px);
  max-height: calc(100% - 60px);
  padding: 30px 60px;
  box-sizing: border-box;
  overflow-y: auto;
  ${mediaMax('small', css`
    padding: 40px 20px 15px;
    max-width: calc(100% - 40px);
  `)};
`;

export const Date = styled.div`
  font-size: 11px;
`;

export const Img = styled.img`
  width: 100%;
  `;

export const Headline = styled.h2``;

export const Text = styled.div``;

export const Cross = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  div {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 3px;
    background-color: ${colors.lightGray};
    transform: translateY(-50%) rotate(45deg);
    border-radius: 3px;
  }
  div.two {
    transform: translateY(-50%) rotate(-45deg);
  }
`;
