import React from 'react';
import { Container, ImgLeft, ImgRight } from './styles';
import MIM_L from './BG_MIM_L.svg';
import MIM_R from './BG_MIM_R.svg';
import BHG_L from './BG_BHG_L.svg';
import BHG_R from './BG_BHG_R.svg';
import HELLMA_L from './BG_HELLMA_L.svg';
import HELLMA_R from './BG_HELLMA_R.svg';
import MWG_L from './BG_MWG_L.svg';
import MWG_R from './BG_MWG_R.svg';
import ROSA_L from './BG_ROSA_L.svg';
import ROSA_R from './BG_ROSA_R.svg';
import WS_L from './BG_WS_L.svg';
import WS_R from './BG_WS_R.svg';

const imgs = {
  mim: {l:MIM_L, r:MIM_R},
  hellma: {l:HELLMA_L, r:HELLMA_R},
  rosa: {l:ROSA_L, r:ROSA_R},
  bhg: {l:BHG_L, r:BHG_R},
  mwg: {l:MWG_L, r:MWG_R},
  kw: {l:WS_L, r:WS_R},
  
};

function Background({orgaID}) {

  const img_left = imgs[orgaID].l;
  const img_right = imgs[orgaID].r;

  return (
    <Container>
      <ImgLeft img={img_left} />
      <ImgRight img={img_right} />
    </Container>
  );
}

export default Background;
