import styled, { css } from "styled-components";
import { FooterHeight, SidebarWidth, SitePaddingX, centerVertically } from "../../styles";
import { mediaMax } from "../../common/breakpoints";

export const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  height: ${FooterHeight.L}px;
  line-height: ${FooterHeight.L}px;
  left: 0;
  right: 0;
  background-color: white;
  font-size: 12px;
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.1));
  padding:  0 ${SitePaddingX.L}px;
  /* line-height: 100px; */
  ${mediaMax('medium', css`
    height: ${FooterHeight.M}px;
    line-height: ${FooterHeight.M}px;
    padding: 0 ${SitePaddingX.M}px;
    font-size: 10px;
  `)}
  ${mediaMax('small', css`
    height: ${FooterHeight.S}px;
    line-height: 30px;
    font-size: 11px;
  `)}
  a {
    color: black;
    text-decoration: none;
  }
  `;

export const LeftAligned = styled.div`
  float: left;
  margin-right: 20px;
  ${mediaMax('small', css`
    clear: both;
  `)}
`;

export const LeftContainer = styled.div`
  ${mediaMax('small', css`
    position: absolute;
    width: 50%;
    left: 20px;
    top: 10px;
  `)}
`;

export const RightContainer = styled(LeftContainer)`
  left: auto;
  right: 20px;
`;

export const FooterText = styled.div`
  ${centerVertically}
  position: absolute;
  right: 250px;
  line-height: 14px;
  left: ${SidebarWidth.L}px;
  color: #888;
  ${mediaMax('medium', css`
    left: 300px;
    right: 220px;
  `)}
  ${mediaMax('small', css`
    display: none;
  `)}
`;

export const RightAligned = styled.div`
  float: right;
  margin-left: 20px;
  ${mediaMax('small', css`
    clear: both;
    margin-bottom: 10px;
  `)}
`;

export const Img = styled.img`
  height: 30px;
  vertical-align: middle;
`;
