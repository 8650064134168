export const getPathAndId = (pathname) => {
  let path_splitted = pathname.split('/');
  const path = path_splitted[1];
  const id = path_splitted[2];
  return {path, id};
};

export const numToWord = (num) => {
  if (num === 0) {
    return 'zero';
  } else if(num === 1) {
    return 'one';
  } else if(num === 2) {
    return 'two';
  } else if(num === 3) {
    return 'three';
  } else if(num === 4) {
    return 'four';
  } else if(num === 5) {
    return 'five';
  } else if(num === 6) {
    return 'six';
  } else if(num === 7) {
    return 'seven';
  } else if(num === 8) {
    return 'eight';
  } else if(num === 9) {
    return 'nine';
  }
};