import create from '../utilities/zustand/create';

const useNavigationStore = create(module, (set, get) => ({
  overlayContent: undefined,
  setOverlayContent: overlayContent => {
    set({overlayContent});
  },
}));

export default useNavigationStore;
