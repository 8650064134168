import { keyframes } from "styled-components";

export const hintLeft = keyframes`
  0% {}
  50% {transform: translateX(-7px)}
  100% {}
`;

export const hintRight = keyframes`
  0% {}
  50% {transform: translateX(7px)}
  100% {}
`;