import styled, { css } from "styled-components";
import { mediaMax } from "../../common/breakpoints";
import { HeaderBottomMargin, HeaderHeight, SidebarWidth, SitePaddingX } from "../../styles";

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: ${SidebarWidth.L}px;
  height: 100%;
  font-size: 16px;
  padding-left: ${SitePaddingX.L}px;
  padding-top: ${HeaderHeight.L + HeaderBottomMargin.L}px;
  padding-top: ${HeaderHeight.L + HeaderBottomMargin.L}px;
  padding-right: 40px;
  box-sizing: border-box;
  ${mediaMax('medium', css`
    padding-top: ${HeaderHeight.M + HeaderBottomMargin.M}px;
    padding-left: ${SitePaddingX.M}px;
    width: ${SidebarWidth.M}px;
    font-size: 14px;
  `)}
  ${mediaMax('small', css`
    display: none;
  `)}
`;

export const MenuElement = styled.div`
  margin-bottom: 10px;
  cursor: pointer;
  /* ${mediaMax('medium', css`
    margin-bottom: 8px;
  `)} */
`;