import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import useDataStore from './services/DataService';
import Menu from './components/Menu';
import { AppContainer } from './styles';
import Footer from './components/Footer';
import useNavigationStore from './services/NavigationService';
import Overlay from './components/Overlay';
import Organization from './components/AppContent/Organization';
import SimplePage from './components/AppContent/SimplePage';
import { useWindowStore } from './services/WindowService';
import MobileMenu from './components/MobileMenu';

function App() {

  const isMobile = useWindowStore(state => state.isMobile);
  const mobileMenuOpen = useWindowStore(state => state.mobileMenuOpen);
  const overlayContent = useNavigationStore(state => state.overlayContent);
  const datenschutz = useDataStore(state => state.datenschutz);
  const impressum = useDataStore(state => state.impressum);

  useEffect(()=>{
    useDataStore.getState().getAll();
    useWindowStore.getState().init();
  }, []);

  return (
    <Router>
      <AppContainer>
        <Routes>
          <Route path="/" element={<Organization orgaID='mim'/>} />
          <Route path="/hellma" element={<Organization orgaID='hellma'/>} />
          <Route path="/rosa" element={<Organization orgaID='rosa'/>} />
          <Route path="/bhg" element={<Organization orgaID='bhg'/>} />
          <Route path="/mwg" element={<Organization orgaID='mwg'/>} />
          <Route path="/kw" element={<Organization orgaID='kw'/>} />
          <Route path="/datenschutz" element={<SimplePage richtext={datenschutz} />} />
          <Route path="/impressum" element={<SimplePage richtext={impressum} />} />
        </Routes>
        <Menu />
        {isMobile && mobileMenuOpen && <MobileMenu />}
        {(!isMobile || mobileMenuOpen) && <Footer />}
        {overlayContent && <Overlay content={overlayContent} />}
      </AppContainer>
      {/* {mobileMenuOpen && <MobileMenuOverlay />} */}
    </Router>
  );
}

export default App;
