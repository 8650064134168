import React from 'react';
import { Container } from '../Organization/styles';
import Background from '../Background';

function SimplePage({richtext}) {

  if (!richtext) return null;

  return (
    <>
      <Background orgaID={'mim'} />
      <Container>
        <div dangerouslySetInnerHTML={{__html: richtext}} style={{maxWidth: 860}}/>
      </Container>
    </>
  );
}

export default SimplePage;
