import React, { useRef, useState } from 'react';
import { GalleryContainer, MainImage, MainImageDescription, PreviewImageContainer, CarouselContainer, PreviewImage, Arrow, ArrowImg } from './styles';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import ArrowLeftImg from '../../assets/images/ArrowLeft.svg';
import ArrowRightImg from '../../assets/images/ArrowRight.svg';

function Gallery({images, langIndex}) {
  
  const [mainImage, setMainImage] = useState(0);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0); // Neuer Zustand für den aktiven Slide-Index
  const carouselRef = useRef();

  const handleImageClick = (index) => {
    setMainImage(index);
  };

  const renderGallerySlides = () => images.map((img, i) => 
    <PreviewImageContainer key={i} onClick={() => handleImageClick(i)}>
      <PreviewImage style={{backgroundImage: `url(${img.img})`}} outline={mainImage === i} />
    </PreviewImageContainer>
  );

  const slidePrev = () => {
    if (!carouselRef.current) return;
    carouselRef.current.slidePrev();
    setActiveSlideIndex(prevIndex => Math.max(0, prevIndex - 1)); // Aktualisiere den aktiven Slide-Index
  };
  
  const slideNext = () => {
    if (!carouselRef.current) return;
    carouselRef.current.slideNext();
    setActiveSlideIndex(prevIndex => Math.min(images.length - 1, prevIndex + 1)); // Aktualisiere den aktiven Slide-Index
  };

  if (images.length < 1) return <></>;

  return (
    <GalleryContainer>
      <MainImage style={{backgroundImage: `url(${images[mainImage].img})`}} />
      <MainImageDescription>{images[mainImage].desc[langIndex]}</MainImageDescription>
      <CarouselContainer hasArrows={images.length > 5}>
        <AliceCarousel
          ref={carouselRef}
          items={renderGallerySlides()}
          infinite
          responsive={{0: {items: 5, itemsFit: 'contain'}}}
          activeIndex={activeSlideIndex} // Verwende den aktiven Slide-Index
          onSlideChanged={(e) => setActiveSlideIndex(e.item)} // Aktualisiere den Zustand, wenn der Slider manuell geändert wird
          syncStateOnPropsUpdate={false}
        />
        {images.length > 5 && (
            <>
              <Arrow onClick={slidePrev}>
                <ArrowImg src={ArrowLeftImg} />
              </Arrow>
              <Arrow onClick={slideNext} right>
                <ArrowImg src={ArrowRightImg} />
              </Arrow>
            </>
          )
        }
      </CarouselContainer>
    </GalleryContainer>
  );
}

export default Gallery;
