import React from 'react';
import { BackgroundImage, CloseButton, Container, HeaderContainer, MenuContainer, OrganizationLogo } from './styles';
import { Link } from 'react-router-dom';
import useDataStore from '../../services/DataService';
import { useWindowStore } from '../../services/WindowService';
import BgImage from '../AppContent/Background/BG_MIM_R.svg';

export default function MobileMenu() {

  const organizations = useDataStore(state => state.organizations);
  // const { page } = useParams();

  const CloseMenu = ()=>{useWindowStore.getState().setMobileMenuOpen(false)};

  const linkTo = o => {
    return o.id === 'mim' ? '/' : '/'+o.id;
  };

  return (
    <Container>
      <BackgroundImage img={BgImage} />
      <HeaderContainer>
        <Link to={linkTo(organizations[0])} onClick={CloseMenu}>
          <OrganizationLogo src={organizations[0].logo} big />
        </Link>
      </HeaderContainer>
      <MenuContainer>
        {organizations.map((o, key)=>
          key === 0 ? null :
            <Link to={linkTo(o)} onClick={CloseMenu} key={key}>
              <OrganizationLogo src={o.logo} />
            </Link>
        )}
      </MenuContainer>
      <CloseButton onClick={CloseMenu}>
        <div className='one' />
        <div className='two' />
      </CloseButton>
    </Container>
  );
}
